<template>
    <div class="init">
        <el-dialog v-model="dialogBool" lock-scroll destroy-on-close
            :title="dialogParmStyle === 'add'?$t('button.add'):$t('button.update')" width="600">
            <el-form :model="fromValue" label-width="auto" ref='form_' class="fromClass"
                :rules='rules' label-position="top">
                <tanAndAppid @getTenantIdAndAppid='getTenantIdAndAppid' :style_='props.dialogParmStyle' ref='tanAndAppid_'/>
                <el-form-item :label="$t('currencySet.name3')" prop="allCoin">
                    <el-input v-model="fromValue.allCoin" disabled  :placeholder="$t('inputTit.xuanze')">
                        <template #append>
                            <el-button text type="primary" @click="chooseCoin">{{$t('button.checks')}}</el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item :label="$t('currencySet.name5')" prop="collectionWaterline">
                    <el-input v-model="fromValue.collectionWaterline" type="number" :min="0" :placeholder="$t('inputTit.shuru')"/>
                </el-form-item><el-form-item :label="$t('currencySet.name6')" prop="hotWalletToplimit">
                    <el-input v-model="fromValue.hotWalletToplimit" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <el-form-item :label="$t('currencySet.name7')" prop="hotWalletWarnLimit">
                    <el-input v-model="fromValue.hotWalletWarnLimit" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <el-form-item :label="$t('currencySet.name8')" prop="gasFeeWarnLimit">
                    <el-input v-model="fromValue.gasFeeWarnLimit" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <el-form-item :label="$t('currencySet.name9')" prop="coldWalletToplimit">
                    <el-input v-model="fromValue.coldWalletToplimit" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <el-form-item :label="$t('currencySet.name10')" prop="minCollectionAmount">
                    <el-input v-model="fromValue.minCollectionAmount"  type="number" :min="0" :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <!-- <el-form-item :label="$t('currencySet._name11')" prop="isCollection">
                    <el-radio-group v-model="fromValue.isCollection" size="large" @change="changeFun_">
                        <el-radio-button :label="1">{{$t('currencySet._name11_1')}}</el-radio-button>
                        <el-radio-button :label="0">{{$t('currencySet._name11_2')}}</el-radio-button>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item :label="$t('currencySet.name11')" prop="isLimit">
                    <el-radio-group v-model="fromValue.isLimit" size="large" @change="changeFun_">
                        <el-radio-button label="1">{{$t('currencySet.name11_1')}}</el-radio-button>
                        <el-radio-button label="0">{{$t('currencySet.name11_2')}}</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <!-- <template v-if='fromValue.isLimit === "1"'>
                    <el-form-item label="是否审核" prop="isAudit">
                        <el-radio-group v-model="fromValue.isAudit" size="large">
                            <el-radio-button label="1">审核</el-radio-button>
                            <el-radio-button label="0">免审</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </template> -->
                <template v-if='fromValue.isLimit === "1"'>
                    <!-- <template v-if='fromValue.isLimit === "1" && fromValue.isAudit === 1'> -->
                    <el-form-item :label="$t('currencySet.name12')" prop="minWithdrawAmount">
                        <el-input v-model="fromValue.minWithdrawAmount" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name13')" prop="maxWithdrawAmountOnce">
                        <el-input v-model="fromValue.maxWithdrawAmountOnce" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                
                    <el-form-item :label="$t('currencySet.name14')" prop="maxWithdrawAmountDay">
                        <el-input v-model="fromValue.maxWithdrawAmountDay" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name15')" prop="dayTime">
                        <el-select v-model="fromValue.dayTime" :placeholder="$t('inputTit.shuru')" size="large" @change="getDate" :teleported='false'>
                            <el-option v-for="item in walletSettingsFrom" :key="JSON.stringify(item)" :value ='item'>
                                {{item[0] + '-' + item[1]}}
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name18')" prop="dayOnceAuditFree">
                        <el-input v-model="fromValue.dayOnceAuditFree"  type="number" :min="0" :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name19')" prop="dayOneAuditNumLimit">
                        <el-input v-model="fromValue.dayOneAuditNumLimit"  type="number" :min="0" :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name20')" prop="dayOneAuditAmountLimit">
                        <el-input v-model="fromValue.dayOneAuditAmountLimit" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name21')" prop="dayAllAuditAmountLimit">
                        <el-input v-model="fromValue.dayAllAuditAmountLimit"  type="number" :min="0" :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name22')" prop="nightTime">
                        <el-input v-model="fromValue.nightTime" disabled/>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name25')" prop="nightOnceAuditFree">
                        <el-input v-model="fromValue.nightOnceAuditFree" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name26')" prop="nightOneAuditNumLimit">
                        <el-input v-model="fromValue.nightOneAuditNumLimit" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name27')" prop="nightOneAuditAmountLimit">
                        <el-input v-model="fromValue.nightOneAuditAmountLimit" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                    <el-form-item  :label="$t('currencySet.name28')" prop="nightAllAuditAmountLimit">
                        <el-input v-model="fromValue.nightAllAuditAmountLimit" type="number" :min="0"  :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                </template>
                <el-form-item :label="$t('currencySet.name29')" prop="googleCode">
                    <el-input v-model="fromValue.googleCode"  :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogBool = false">{{$t('button.closeQ')}}</el-button>
                    <el-button type="primary" @click="completeFun(form_)">{{$t('button.enter')}}</el-button>
                </span>
            </template>
        </el-dialog>
         <el-dialog v-model="innerVisible"
            width="50%"
            :title="$t('currencySet.name30')"
            append-to-body
        >
            <coinView v-if='innerVisible' @getCoin='getCoin' ref='coinView_'/>
        </el-dialog>
    </div>
</template>
<script setup>
    import { defineProps,ref,defineExpose,reactive,nextTick,defineEmits,getCurrentInstance } from 'vue'
    // import moment from "moment";
    import coinView from './coin.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    import { walletSettingsFrom } from '@/utils/pageData'
    import tanAndAppid from '../commom/tenAndAppid.vue'

    const fromValue = reactive({ 
        tenantId:'',
        appId:'',
        allCoin:'',chain:'',protocol:'',coin:'',coinId:'',
        contractAddr:'',
        collectionWaterline:'',
        hotWalletToplimit:'',
        hotWalletWarnLimit:'',
        gasFeeWarnLimit:'',
        coldWalletToplimit:'',
        minCollectionAmount:'',
        isLimit:"0",
        // isCollection: 0,
        // isAudit: '0',
        minWithdrawAmount: '',
        maxWithdrawAmountDay:'',
        maxWithdrawAmountOnce:'',
        dayTime:'',dayStartTime:'',dayEndTime:'',
        dayOnceAuditFree:'',
        dayOneAuditNumLimit:'',
        dayOneAuditAmountLimit:'',
        dayAllAuditAmountLimit:'',
        nightTime:'',nightStartTime:'',nightEndTime:'',
        nightOnceAuditFree:'',
        nightOneAuditNumLimit:'',
        nightOneAuditAmountLimit:'',
        nightAllAuditAmountLimit:'',
        isBaseCoin:'',
        googleCode:''
    })
    const emit = defineEmits(['successFun'])
    const tenantArray = ref([])
    const appArray = ref([])
    const innerVisible = ref(false)
    const form_ = ref(null)
    const rules = ref(null);rules.value = addRules.mainRules
    const dialogBool = ref(false)
    const { $t } = getCurrentInstance().proxy;
    const coinView_ = ref(null)
    const tanAndAppid_ = ref(null)

    const props = defineProps({ //子组件接收父组件传递过来的值
        dialogParmStyle: String
    })
    const getDate = (item)=>{
       fromValue.dayTime = item[0] + '-' + item[1]
       fromValue.dayStartTime = item[0]
       fromValue.dayEndTime = item[1]
       fromValue.nightTime = item[2] + '-' + item[3]
       fromValue.nightStartTime = item[2]
       fromValue.nightEndTime = item[3]
    }
    const changeFun_ = (e)=>{
        if(e === '1'){
            rules.value = Object.assign({}, addRules.mainRules, addRules.moreRules)
        }else{
            rules.value = addRules.mainRules
        }
    }
    const getTenantIdAndAppid = (e)=>{//获得公共组件中的app和商户
        if(e){
            fromValue.tenantId = e.tenantId
            fromValue.appId = e.appId
        }
    }
    const completeFun = (form_)=>{
        if (!form_) return
        form_.validate((e) => {
            if(e){
                if(fromValue.isLimit === "1" && fromValue.maxWithdrawAmountOnce > fromValue.maxWithdrawAmountDay){
                    // if(fromValue.isLimit === "1" && fromValue.isAudit === 1 && fromValue.maxWithdrawAmountOnce > fromValue.maxWithdrawAmountDay){
                    ElNotification({
                        message: '单笔提现上限应小于单日提现上限',
                        type: 'error',
                    })
                    return 
                }
            let obj_ = JSON.parse(JSON.stringify(fromValue))
            obj_.dayStartTime += ':00' 
            obj_.dayEndTime += ':00' 
            obj_.nightStartTime += ':00' 
            obj_.nightEndTime += ':00' 
            delete obj_.allCoin
            delete obj_.dayTime
            delete obj_.nightTime
            delete obj_.googleCode
            // fromValue.isAudit = fromValue.isAudit.toString()
            if(props.dialogParmStyle === 'add'){
                if(fromValue.isLimit === '0'){
                    // if(fromValue.isLimit === '0' && fromValue.isAudit === '0'){
                    delete obj_.dayEndTime
                    delete obj_.dayStartTime
                    delete obj_.nightStartTime
                    delete obj_.nightEndTime
                }
                Api_.addCurrencyShopObj(obj_,fromValue.googleCode).then(res=>{
                    if(res.code === 0){
                        emit('successFun')
                        dialogBool.value = false
                        ElNotification({
                            message: $t('alert.succAdd'),
                            type: 'success',
                        })
                    }else{
                        ElNotification({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }else{
                if(fromValue.isLimit === '0'){
                    // if(fromValue.isLimit === '0' && fromValue.isAudit === 0){
                    delete obj_.dayEndTime
                    delete obj_.dayStartTime
                    delete obj_.nightStartTime
                    delete obj_.nightEndTime
                }
                Api_.updateCurrencyShopObj(obj_,fromValue.googleCode).then(res=>{
                    if(res.code === 0){
                        emit('successFun')
                        dialogBool.value = false
                        ElNotification({
                            message: $t('alert.succUpdate'),
                            type: 'success',
                        })
                    }else{
                        ElNotification({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }
            }
        })
    }
    const chooseCoin = ()=>{
        if(props.dialogParmStyle === 'add'){
            innerVisible.value = true
            nextTick(()=>{
                coinView_.value.init_()
            })
        }
    }
    const setData = (e1,e2,scope)=>{//获得列表数据
        tenantArray.value = e1.value 
        appArray.value  = e2.value 
        dialogBool.value = true
        fromValue.googleCode = ''
        fromValue.dayTime = ''
        fromValue.nightTime = ''
        nextTick(()=>{
            if(props.dialogParmStyle === 'add'){
                form_.value.resetFields()
                delete fromValue.tenantCoinId
            }else{//修改遍历对象
                for(let i in scope){
                    fromValue[i] = scope[i]
                }
                fromValue.isLimit = fromValue.isLimit?fromValue.isLimit.toString():'0'
                fromValue.allCoin = fromValue.chain +(fromValue.protocol?'/' + fromValue.protocol+'/':'/')+fromValue.coin;
                tanAndAppid_.value.setData(scope.tenantName,scope.appName)
                if(scope.dayStartTime){
                    fromValue.dayStartTime = fromValue.dayStartTime.slice(0,-3);
                    fromValue.dayEndTime = fromValue.dayEndTime.slice(0,-3);
                    fromValue.nightStartTime = fromValue.nightStartTime.slice(0,-3);
                    fromValue.nightEndTime = fromValue.nightEndTime.slice(0,-3);
                    fromValue.dayTime = fromValue.dayStartTime + '-' + fromValue.dayEndTime
                    fromValue.nightTime = fromValue.nightStartTime + '-' + fromValue.nightEndTime
                }
            } 
        })
    }
    const getCoin = (obj_)=>{
        innerVisible.value = false
        fromValue.allCoin = obj_.chain + (obj_.protocol?'/' + obj_.protocol+'/':'/')+obj_.coin;
        fromValue.chain = obj_.chain;
        fromValue.protocol = obj_.protocol;
        fromValue.coin = obj_.coin;
        fromValue.coinId = obj_.coinId;
        fromValue.isBaseCoin = obj_.coinType;
        fromValue.minCollectionAmount = obj_.minCollectionAmount;
        fromValue.contractAddr = obj_.contractAddr;
    }
    defineExpose({setData})
</script>