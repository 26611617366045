<template>
    <div>
        <el-table :data="list" border v-loading='listLoading' height="350">
            <el-table-column prop="selection" :label="$t('currencySetNft.text8')" width="100" >
                <template #default="scope">
                    <el-checkbox size="large" @change="changeFun(scope.row)" v-model='checkItem'/>
                </template>
            </el-table-column>
            <el-table-column :label="$t('currencySet.text5')"  >
                <template #default="scope">
                    {{scope.row.chain}} / {{scope.row.protocol}}
                </template>
            </el-table-column>
            <el-table-column prop="coin" :label="$t('currencySet.text1')" width="100" />
            <el-table-column label="Icon" width="100">
                <template #default="scope">
                    <img :src="scope.row.coinIcon" alt="Icon" style='width:40px'/>
                </template>
            </el-table-column>
            <el-table-column :label="$t('currencySet.text2')" >
                <template #default="scope">
                    {{scope.row.coinType === 1?$t('currencySet.text3'):$t('currencySet.text4')}}
                </template>
            </el-table-column>
        </el-table>
        <pageCom :page='page' @sizeChange='sizeChange' @currentChange='currentChange'/>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,reactive,defineEmits,defineExpose } from 'vue'
    import pageCom from '@/components/pageCom.vue'
    const checkItem = ref(false)
    
    const list = ref([])
    const listLoading = ref(false)
    const page = reactive({
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
    })
    const emit = defineEmits(['getCoin'])
    const changeFun = (e)=>{
        emit('getCoin',{
            chain: e.chain,
            protocol: e.protocol,
            coin: e.coin,
            coinId: e.coinId,
            coinType: e.coinType,
            minCollectionAmount: e.minCollection,
            contractAddr: e.contractAddr,
        })
    }
    const getList = (e)=>{
        let obj_ = {
            current: page.currentPage,
            size: page.pageSize
        }
        if(e && e !== 1){
            obj_ = Object.assign({}, obj_, e)
        }
        listLoading.value = true
        Api_.getCurrencyList(obj_).then(res=>{
            if(res.code === 0){
                listLoading.value = false
                list.value = res.data.records
                page.total = res.data.total
            }
        })
    }
    const sizeChange = (pageSize)=>{ 
        page.pageSize = pageSize;
        getList(1)
    }
    const currentChange = (currentPage)=>{ 
        page.currentPage = currentPage;
        getList(1)
    }
    const init_ = ()=>{
        checkItem.value = false
        getList(1)
    }
    defineExpose({init_})
</script>

<style lang="scss" scoped>

</style>